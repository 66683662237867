var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "candidate-item" }, [
    !_vm.item.isImported
      ? _c("div", { staticClass: "private-img" }, [
          _c("img", {
            attrs: { src: require("@src/assets/images/private_candidate.png") },
          }),
        ])
      : _vm._e(),
    _c("div", { staticClass: "candidate-item-main" }, [
      _c(
        "div",
        { staticClass: "candidate-item-main-left" },
        [
          _c("el-checkbox", {
            attrs: { size: "medium", disabled: _vm.item.isImported },
            model: {
              value: _vm.item.isSelected,
              callback: function ($$v) {
                _vm.$set(_vm.item, "isSelected", $$v)
              },
              expression: "item.isSelected",
            },
          }),
          _c("a", {
            staticClass: "candidate-name",
            attrs: {
              href: "#/candidateDetail/" + _vm.item.tbdResumeId,
              target: "_blank",
            },
            domProps: { textContent: _vm._s(_vm.item.realName) },
            on: {
              click: function ($event) {
                return _vm.viewDetail(_vm.item)
              },
            },
          }),
          _vm.item.yearOfExperienceInfo
            ? _c("span", { staticClass: "info-text candidate-experience" }, [
                _vm._v(_vm._s(_vm.item.yearOfExperienceInfo)),
              ])
            : _vm._e(),
          _vm.item.yearOfExperienceInfo
            ? _c("i", { staticClass: "center-line" })
            : _vm._e(),
          _vm.item.birthday && _vm.item.birthday.indexOf("0001-01-01") != 0
            ? _c("span", { staticClass: "info-text candidate-age" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm._f("birthdayToAge")(_vm.item.birthday)) +
                    "\n            "
                ),
              ])
            : _vm._e(),
          _vm.item.birthday && _vm.item.birthday.indexOf("0001-01-01") != 0
            ? _c("i", { staticClass: "center-line" })
            : _vm._e(),
          _vm.item.gender || _vm.item.gender == 0
            ? _c("span", { staticClass: "info-text candidate-gender" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.genderMap[_vm.item.gender]) +
                    "\n            "
                ),
              ])
            : _vm._e(),
          _vm.item.gender || _vm.item.gender == 0
            ? _c("i", { staticClass: "center-line" })
            : _vm._e(),
          _c("span", { staticClass: "info-text candidate-location" }, [
            _vm._v(_vm._s(_vm.item.locationInfo)),
          ]),
          _c("span", { staticClass: "info-text candidate-time" }, [
            _vm._v("更新时间：" + _vm._s(_vm.item.created || _vm.item.updated)),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "candidate-item-main-right" },
        [
          !_vm.item.isImported
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm._onBatchImportClick(_vm.item.tbdResumeId)
                    },
                  },
                },
                [_vm._v("导入人才库")]
              )
            : _c("span", [_vm._v("公司库已存在")]),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "information-body" }, [
      _c("div", { staticClass: "information-body-block" }, [
        _vm.item.workExperienceList && _vm.item.workExperienceList.length > 0
          ? _c(
              "div",
              { staticClass: "employer-info-list" },
              [
                _c("span", { staticClass: "title-line" }, [
                  _vm._v("\n                    1近期工作：\n                "),
                ]),
                _vm._l(
                  _vm.item.workExperienceList,
                  function (employerInfo, index) {
                    return _c("div", { key: index, staticClass: "info-item" }, [
                      _c("div", { staticClass: "info-item-left" }, [
                        _c("span", { staticClass: "company" }, [
                          _vm._v(_vm._s(employerInfo.companyName)),
                        ]),
                        _c("span", { staticClass: "title" }, [
                          _vm._v(_vm._s(employerInfo.title)),
                        ]),
                      ]),
                      !(
                        _vm.formatToMonth(employerInfo.startDate) ==
                          "时间未知" &&
                        _vm.formatToMonth(employerInfo.endDate) == "时间未知"
                      )
                        ? _c("div", { staticClass: "info-item-right" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.formatToMonth(employerInfo.startDate)
                                ) +
                                  "-" +
                                  _vm._s(
                                    _vm.formatToMonth(employerInfo.endDate)
                                  )
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ])
                  }
                ),
              ],
              2
            )
          : _vm._e(),
      ]),
      _c("div", { staticClass: "information-body-block" }, [
        _vm.item.educations && _vm.item.educations.length > 0
          ? _c("div", { staticClass: "degree-info-list" }, [
              _c("span", { staticClass: "title-line" }, [
                _vm._v("\n                    最高学历：\n                "),
              ]),
              _c("div", { staticClass: "info-item" }, [
                _c("div", { staticClass: "info-item-left" }, [
                  _c("span", { staticClass: "school" }, [
                    _vm._v(_vm._s(_vm.item.educations[0].schoolName)),
                  ]),
                  _vm._v(
                    "\n                        -\n                        "
                  ),
                  _c("span", { staticClass: "major" }, [
                    _vm._v(_vm._s(_vm.item.educations[0].majorName)),
                  ]),
                  _c("span", { staticClass: "degree" }, [
                    _vm._v(
                      _vm._s(_vm.degreeMap[_vm.item.educations[0].degree])
                    ),
                  ]),
                ]),
                !(
                  _vm.formatToMonth(_vm.item.educations[0].startDate) ==
                    "时间未知" &&
                  _vm.formatToMonth(
                    _vm.item.educations[0].endDate == "时间未知"
                  )
                )
                  ? _c("div", { staticClass: "info-item-right" }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.formatToMonth(_vm.item.educations[0].startDate)
                          ) +
                            "-" +
                            _vm._s(
                              _vm.formatToMonth(_vm.item.educations[0].endDate)
                            )
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="candidate-item">
        <div class="private-img" v-if="!item.isImported">
            <img src="@src/assets/images/private_candidate.png" />
        </div>
        <div class="candidate-item-main">
            <div class="candidate-item-main-left">
                <el-checkbox size="medium" :disabled="item.isImported" v-model="item.isSelected"></el-checkbox>
                <a class="candidate-name" v-text="item.realName" :href="'#/candidateDetail/'+ item.tbdResumeId" @click="viewDetail(item)" target="_blank"></a>

                <span class="info-text candidate-experience" v-if="item.yearOfExperienceInfo">{{item.yearOfExperienceInfo}}</span>
                <i class="center-line" v-if="item.yearOfExperienceInfo"></i>
                <span class="info-text candidate-age" v-if="item.birthday && item.birthday.indexOf('0001-01-01') != 0">
                    {{item.birthday | birthdayToAge }}
                </span>
                <i class="center-line" v-if="item.birthday && item.birthday.indexOf('0001-01-01') != 0"></i>
                <span class="info-text candidate-gender" v-if="item.gender || item.gender == 0">
                    {{genderMap[item.gender]}}
                </span>
                <i class="center-line" v-if="item.gender || item.gender == 0"></i>
                <span class="info-text candidate-location">{{item.locationInfo}}</span>

                <span class="info-text candidate-time">更新时间：{{item.created || item.updated}}</span>
            </div>
            <div class="candidate-item-main-right">
                <el-button v-if="!item.isImported" type="primary" @click="_onBatchImportClick(item.tbdResumeId)">导入人才库</el-button>
                <span v-else>公司库已存在</span>
            </div>
        </div>

        <div class="information-body">
            <div class="information-body-block">
                <div class="employer-info-list" v-if="item.workExperienceList && item.workExperienceList.length > 0">
                    <span class="title-line">
                        1近期工作：
                    </span>
                    <!-- <div class="info-item">
                        <div
                            class="employer-info-item ellipsis"
                            v-html="employerInfo"
                            v-for="(employerInfo, index) in item.workExperiences"
                            :key="index"
                        >
                        </div>
                    </div> -->
                    <div class="info-item" v-for="(employerInfo, index) in item.workExperienceList" :key="index">
                        <div class="info-item-left">
                            <span class="company">{{employerInfo.companyName}}</span>
                            <span class="title">{{employerInfo.title}}</span>
                        </div>
                        <div class="info-item-right" v-if="!(formatToMonth(employerInfo.startDate) == '时间未知' && formatToMonth(employerInfo.endDate) == '时间未知')">
                            <span>{{formatToMonth(employerInfo.startDate)}}-{{formatToMonth(employerInfo.endDate)}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="information-body-block">
                <div class="degree-info-list" v-if="item.educations && item.educations.length > 0">
                <!-- <div class="degree-info-list"> -->
                    <span class="title-line">
                        最高学历：
                    </span>
                    <!-- <div class="info">
                        <div
                            class="degree-info-item ellipsis"
                            v-html="item.item.educations[0]"
                        ></div>
                    </div> -->
                    <div class="info-item">
                        <div class="info-item-left">
                            <span class="school">{{item.educations[0].schoolName}}</span>
                            -
                            <span class="major">{{item.educations[0].majorName}}</span>
                            <span class="degree">{{degreeMap[item.educations[0].degree]}}</span>
                        </div>
                        <div class="info-item-right" v-if="!(formatToMonth(item.educations[0].startDate) == '时间未知' && formatToMonth(item.educations[0].endDate == '时间未知'))">
                            <span>{{formatToMonth(item.educations[0].startDate)}}-{{formatToMonth(item.educations[0].endDate)}}</span>
                        </div>
                    </div>
                    <!-- <div class="info-item" v-for="(employerInfo, index) in workExperiences" :key="index"> -->
                    <!-- <div class="info-item">
                        <div class="info-item-left">
                            <span class="school">湖南大学</span>
                            -
                            <span class="major">计算机软件工程</span>
                            <span class="degree">本科</span>
                        </div>
                        <div class="info-item-right">
                            <span>2019.11-2020.01</span>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components:{
    },
    props:{
        item: Object,
        rankIndex: Number
    },
    data(){
        return{
            genderMap: ['未知', '男', '女'],
            degreeMap: ['无',"高中及以下","大专","本科","硕士","MBA","博士"],
        }
    },
    filters: {
        birthdayToAge(birthday) {
            return ((new Date).getFullYear() - birthday.slice(0, 4)) + '岁';
        }
    },
    mounted(){
    },
    methods: {
        formatToMonth(date) {
            if (!date || date == '0001-01-01') {
                return '时间未知';
            } else if(date.indexOf('9999') == 0){
                return '至今';
            }
            return date.slice(0, 7).replace('-', '.');
        },
        handleCheckItem(val){
            // let tbdResumeId = candidateInfo.tbdResumeId;
            // this.$emit('check-item',{id:tbdResumeId, active: val})
        },

        viewDetail(item){
            let eventData = {
                rankIndex: this.rankIndex,
                item: item
            };
            this.$emit("personal-viewDetail", eventData);
        },

        _onBatchImportClick(val) {
            this.$emit('event-batchImport-click',val)
        }
    },
};
</script>
<style lang="scss" scoped>
%ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.candidate-item {
    // height: 102px;
    position: relative;
    padding: 0 16px 12px;
    margin-bottom: 10px;
    background: #FFFFFF;
    border: 1px solid #fff;
    border-radius: 10px;
    overflow: hidden;

    &:hover {
        border: 1px solid $primary;
    }

    .private-img {
        position: absolute;
        top: 21px;
        left: 32px;
        pointer-events: none;

        img {
            display: block;
            width: 78px;
            height: 78px;
        }
    }

    &-main {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 66px;
        margin-left: 30px;

        &-left {
            position: relative;
            height: 100%;
            display: flex;
            align-items: center;

            /deep/ .el-checkbox {
                position: absolute;
                top: 50%;
                left: -30px;
                margin-top: -12px;

                .el-checkbox__inner {
                    width: 18px;
                    height: 18px;
                    border-color: #999;
    
                    &:after {
                        width: 4px;
                        height: 8px;
                        top: 2px;
                        left: 6px;
                    }
                }
                .el-checkbox__input.is-focus .el-checkbox__inner, .el-checkbox__input.is-checked .el-checkbox__inner {
                    border-color: $primary;
                }
            }

            .candidate-name {
                font-size: 18px;
                color: #333;
                font-weight: 600;
                max-width: 200px;
                display: inline-block;
                margin-right: 16px;
                @extend %ellipsis;
            }

            .center-line {
                display: inline-block;
                width: 1px;
                height: 14px;
                margin: 0 8px;
                background: #DCDCDC;
            }

            .candidate-time {
                margin-left: 60px;
            }
        }
    }

    .information-body {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-top: 12px;
        border-top: 1px solid rgba(235,235,235,0.92);

        &-block {
            flex: 1;
            max-width: 46%;
            line-height: 22px;
            color: #666;
            font-size: 14px;
            flex-shrink: 0;
            overflow: hidden;

            .title-line {
                display: block;
                color: #333;
                line-height: 20px;
                margin-bottom: 6px;
            }

            .info-item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 2px;

                &:last-child {
                    margin-bottom: 0;
                }

                &-left {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    overflow: hidden;
                }

                &-right {
                    width: 120px;
                    text-align: right;
                    flex-shrink: 0;
                    color: #999;
                }

                .company, .title, .school, .major {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .company {
                    max-width: 100%;
                    min-width: 60px;
                    padding-right: 16px;
                }
                .title {
                    max-width: 100%;
                    min-width: 60px;
                }
                .school {
                    max-width: 40%;
                }
                .major {
                    max-width: 40%;
                }
                .degree {
                    padding-left: 6px;
                    flex-shrink: 0;
                }
            }

            .info {
                // width: 376px;
                width: 80%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
            // .degree-info-item,
            // .employer-info-item {
            //     width: 100%;
            //     margin-bottom: 2px;

            //     &:last-child {
            //         margin-bottom: 0;
            //     }
            // }
        }
        &-right {
            width: 360px;
            justify-content: space-between;
        }
    }
}

</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "personal-candidate-view" },
        [
          _c("personal-search-area", {
            ref: "personalSearchArea",
            attrs: {
              "page-view-id": _vm.pageViewId,
              isAllSelected: _vm.isAllSelected,
            },
            on: {
              "action-dosearch": _vm.handleDoSearch,
              "event-batchImport-click": _vm.handleBatchImport,
              "check-all": _vm.handleCheckAll,
            },
          }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "candidate-list",
            },
            [
              _vm.candidateList.length == 0 && !_vm.loading
                ? _c("div", { staticClass: "page-empty" }, [
                    _c("img", {
                      attrs: {
                        src: require("@src/assets/images/jobList/empty-bg@2x.png"),
                      },
                    }),
                    _c("p", { staticClass: "des" }, [
                      _vm._v("没有搜索到任何人才"),
                    ]),
                  ])
                : _vm._e(),
              _vm.candidateList.length > 0
                ? _vm._l(_vm.candidateList, function (candidate, index) {
                    return _c("candidate-item", {
                      key: candidate.tbdResumeId,
                      attrs: { rankIndex: index, item: candidate },
                      on: {
                        "personal-viewDetail": _vm.handleViewDetail,
                        "event-batchImport-click": _vm.handleBatchImport,
                      },
                    })
                  })
                : _vm._e(),
            ],
            2
          ),
          _vm.candidateList.length > 0
            ? _c("el-pagination", {
                staticClass: "candidate-list-pagination",
                attrs: {
                  "current-page": _vm.pager.current,
                  "page-sizes": [20, 30, 50],
                  "page-size": _vm.pager.size,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.pager.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "el-confirmDialog",
          attrs: { title: "提示", visible: _vm.ConfirmDialog, width: "400px" },
          on: {
            "update:visible": function ($event) {
              _vm.ConfirmDialog = $event
            },
          },
        },
        [
          _c("span", [_vm._v("确定导入到企业人才库？")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.ConfirmDialog = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleConfirm },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("import-result-dialog", {
        ref: "importResultDialog",
        on: {
          "import-confirm": _vm.handleResultConfirm,
          "import-then-compare": _vm.goToCompare,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div>
        <div class="personal-candidate-view">
            <personal-search-area 
                ref="personalSearchArea" 
                @action-dosearch="handleDoSearch" 
                @event-batchImport-click="handleBatchImport" 
                @check-all="handleCheckAll" 
                :page-view-id="pageViewId"
                :isAllSelected="isAllSelected"
            ></personal-search-area>
            <div class="candidate-list" v-loading="loading">
                <!-- <div class="candidate-list-empty" v-if="candidateList.length == 0 && !loading">
                    <span class="empty-img"></span>
                    <p class="empty-data">暂无数据</p>
                </div> -->
                <div class="page-empty" v-if="candidateList.length == 0 && !loading">
                    <img src="~@src/assets/images/jobList/empty-bg@2x.png" />
                    <p class="des">没有搜索到任何人才</p>
                </div>
                <!-- <personal-candidate-store ref="personalCandidateStore" @list-param-start="handlePagerStart" @event-batchImport-click="handleBatchImport" @order-change-take="hanldeTakeChange" @personal-viewDetail="handleViewDetail" @check-item="handleCheckItem" :page-view-id="pageViewId"></personal-candidate-store> -->
                <template v-if="candidateList.length > 0">
                    <candidate-item
                        v-for="(candidate, index) in candidateList"
                        :key="candidate.tbdResumeId"
                        :rankIndex="index"
                        :item="candidate"
                        @personal-viewDetail="handleViewDetail"
                        @event-batchImport-click="handleBatchImport"
                    ></candidate-item>
                </template>
            </div>
            <el-pagination
                :current-page="pager.current"
                :page-sizes="[20, 30, 50]"
                :page-size="pager.size"
                class="candidate-list-pagination"
                layout="total, sizes, prev, pager, next, jumper"
                :total="pager.total"
                v-if="candidateList.length > 0"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            >
                <!-- <span class="pagination-text">
                    <span>
                        前往
                        <el-input v-model="pagerJump" @keyup.enter.native="handlePagerJump(pagerJump)"></el-input>
                        页
                    </span>
                    <span @click="handlePagerJump(pagerJump)">
                        跳转
                    </span>
                </span> -->
            </el-pagination>
        </div>

        <el-dialog class="el-confirmDialog" title="提示" :visible.sync="ConfirmDialog" width="400px">
            <span>确定导入到企业人才库？</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="ConfirmDialog = false">取消</el-button>
                <el-button type="primary" @click="handleConfirm">确定</el-button>
            </span>
        </el-dialog>

        <import-result-dialog
            ref="importResultDialog"
            @import-confirm="handleResultConfirm"
            @import-then-compare="goToCompare"
        ></import-result-dialog>
    </div>
</template>
<script>
import { candidate as candidateUrl } from '#/js/config/api.json';

import personalSearchArea from './search.vue';
import candidateItem from './candidate-item.vue';
import ImportResultDialog from '#/component/common/dialog/importResultDialog.vue';

export default {
    components:{
        personalSearchArea,
        candidateItem,
        ImportResultDialog
    },
    props:{
        pageViewId:String
    },
    data(){
        return {
            loading: false,
            candidateList: [],
            pager: {
                current: 1,
                size: 20,
                total: 0
            },
            pagerJump: 0,
            params:{},
            isFirstLoad:true,
            myBoardStartDate:'',
            myBoardEndDate:'',
            enableAutoSearch:true,
            searchCount:0,
            selectedItem:[],
            successImportNumber:0,
            ConfirmDialog:false,
            searchId:'',
            selectAllLen:0,
            isbyHotSearch:false,
            isAllSelected: false,
            isSingleImport: false,
            singleImportId: ''
        }
    },
    mounted(){
        let self = this;
        this.searchArea = this.$refs.personalSearchArea;
        this.search({isFreshSearch:true});
    },
    methods: {
        handleViewDetail(val){
            lbdTrack.track('personalCandidateViewDetail',{
                id:val.item.id,
                isbyHotSearch: this.isbyHotSearch,
                type:"detail",
                searchId: this.searchId,
                pageIndex: this.pager.current,
                itemRank: val.rankIndex,
                candidateId: val.item.id,
                tbdResumeId: val.item.tbdResumeId
            });
        },
        handleBatchImport(val){
            if(val){
                this.isSingleImport = true;
                this.singleImportId = val;
                this._showConfirmDialog();
            }else{
                this.isSingleImport = false;
                this._onBatchImport();
            }
        },
        handleDoSearch(val){
            this.search(val.params);
            this.isbyHotSearch = val.isbyHotSearch;
        },
        search(params, silent) {
            if(params){
                Object.assign(this.params, params);
            }
            if(!silent) {
                this.pager.current = 1;
            }
            Object.assign(this.params, {
                start: (this.pager.current - 1) * this.pager.size,
                take: this.pager.size,
                positionQueryType: 0
            });
            if(this.searchCount >= 1 && this.params.isFreshSearch === true){
                this.params.sort = 0;
            };
            this.searchCount++;

            //
            this.loading = true;
            _request({
                url: candidateUrl.my_candidate_list,
                method: 'POST',
                data: this.params
            }).then(res => {
                this.clearCandidateSelect();
                let list = res.list || [];
                let canSelectCount = 0;
                list.forEach(item => {
                    item.isSelected = false;
                    if(!item.isImported){
                        canSelectCount++;
                    }
                })
                this.selectAllLen = canSelectCount;
                this.candidateList = list;
                this.pager.total = res.total;
                this.searchArea.summary.total = res.total;
                this.searchId = res.searchId;
            }).finally(() => {
                this.loading = false;
            })
            // this.personalCandidateStore.loadListData(this.params, this.isFirstLoad).done((rtn) => {
            //     this.clearCandidateSelect();
            //     this.searchArea.summary.total=rtn.data.total;
            //     this.isFirstLoad = false;
            //     if(this.params.isFreshSearch){
            //         this.searchId = rtn.data.searchId;
            //     };
            // });
        },
        handleCheckAll(val){
            this.isAllSelected = val;            
            this.candidateList.forEach(item => {
                if(!item.isImported) {
                    item.isSelected = val;
                }
            })

            if (this.isAllSelected) {
                let selectedLength = 0;
                this.candidateList.forEach(item => {
                    if(item.isSelected) {
                        selectedLength += 1;
                    }
                })
                if (selectedLength == 0) {
                    shortTips('当前页暂无可导入人才库人才');
                    this.isAllSelected = false;
                }
            }
        },
        scrollListTop() {
            if(document.querySelector(".search-summary")) {
                document.querySelector('.site-candidates').scrollTop = document.querySelector('.candidate-list').offsetTop - document.querySelector(".search-summary").clientHeight - 20 - 60;
            } else {
                document.querySelector('.site-candidates').scrollTop = document.querySelector('.candidate-list').offsetTop - 60;
            }
        },
        handleSizeChange(val){
            this.pager.size = val;
            this.pager.current = 1;
            this.$nextTick(() => {
                this.search(this.params, true);
                this.scrollListTop();
            })
        },
        handleCurrentChange(current) {
            this.pager.current = parseInt(current) || 1;
            this.scrollListTop();
            this.search(this.params, true);
        },
        handlePagerJump(pagerJump) {
            if(!parseInt(pagerJump)) {
                return false;
            }
            if(pagerJump > Math.ceil(this.pager.total/this.pager.size)) {
                return;
            } else {
                this.handleCurrentChange(pagerJump);
            }
        },
        _onBatchImport(){
            let selectedItemLen = 0;
            this.candidateList.forEach(item => {
                if(item.isSelected) {
                    selectedItemLen += 1;
                }
            })

            if(!selectedItemLen){
                shortTips('还没有选中人才');
                return;
            }
            this._showConfirmDialog();
        },

        _showConfirmDialog(){
            this.ConfirmDialog = true;
        },
        handleConfirm(){
            this.ConfirmDialog = false;
            this._batchImport();
        },
        _showResultDialog(data){
            this.successImportNumber = data.successCount;
            this.$refs.importResultDialog.show(data);
        },
        handleResultConfirm(){
            if(this.successImportNumber){
                this.search(this.params, true);
            }
        },
        _batchImport(){
            let importIdArr = [];
            if(this.isSingleImport) {
                importIdArr = [this.singleImportId];
            }else {
                this.candidateList.forEach(item => {
                    if(item.isSelected) {
                        importIdArr.push(item.tbdResumeId);
                    }
                })
            }

            let importLoading = this.$loading();
            _request({
                url: candidateUrl.import_personal_candidate_to_firm,
                method: 'POST',
                data: {
                    tbdResumeIds: importIdArr    
                }
            }).then(res => {
                this.clearCandidateSelect();
                this._showResultDialog(res);
            }).finally(() => {
                importLoading.close();
            })
        },

        clearCandidateSelect() {
            this.selectedItem=[];
            this.isAllSelected = false;

            this.candidateList.forEach(item => {
                item.isSelected = false;
            })
        },
        goToCompare(resume) {
            if(resume && resume.repeatResumeId) {
                window.open(`/Headhunting/MyCompany.html#/candidate/updatecheck?resumeId=${resume.resumeId}&originalResumeId=${resume.repeatResumeId}`, '_blank');
                // window.open(`/#/candidate/updatecheck?resumeId=${resume.resumeId}&originalResumeId=${resume.repeatResumeId}`, '_blank');
            } else {
                shortTips("系统异常，请稍后重试");
            }
        }
    },
};

</script>
<style lang="scss">
$itemBorder: 1px solid #e1e1e1;

.personal-candidate-view{

    .candidate-list {
        min-height: 100px;
        // padding: 0 20px;
        // background-color: #ffffff;

        .el-loading-spinner {
            top: 21px;
        }

        // &-empty {
        //     min-height: 200px;
        //     display: flex;
        //     align-items: center;
        //     justify-content: center;
        //     flex-direction: column;
        //     background-color: #ffffff;
        //     .empty-img {
        //         display: inline-block;
        //         background: url('~@src/assets/images/home/icon_no_data.svg') no-repeat;
        //         background-size: contain;
        //         width: 170px;
        //         height: 160px;
        //     }
        //     .empty-data {
        //         font-size: 14px;
        //         margin-bottom: 0px;
        //     }
        // }
        .page-empty{
            width: 100%;
            // height: 383px;
            min-height: calc(100vh - 436px);
            background: white;
            border-radius: 10px;
            text-align: center;
            >img{
                width: 158px;
                height: 178px;
                margin: 73px auto 0;
            }
            .des{
                margin: 24px auto 0;
                font-size: 14px;
                font-weight: 500;
                color: #999999;
                line-height: 20px;
            }
        }
    }

    .candidate-list-pagination.el-pagination {
        text-align: center;
        padding-top: 6px;

        button.btn-prev, button.btn-next {
            background-color: transparent;
        }

        button:disabled {
            background-color: transparent;
        }

        .el-pager li {
            background-color: transparent;
        }
    }
}
</style>


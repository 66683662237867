var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "personal-search-vue" },
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "search-area" },
        [
          _c(
            "el-form",
            {
              ref: "personalCandidateSearchForm",
              staticClass: "personal-search-form",
              attrs: {
                size: "mini",
                model: _vm.personalCandidateSearch,
                rules: _vm.personalCandidateSearchRules,
              },
            },
            [
              _c(
                "el-form-item",
                { staticClass: "location-item", attrs: { prop: "location" } },
                [
                  _c("div", { staticClass: "search-block" }, [
                    _c(
                      "div",
                      { staticClass: "search-city-box" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "search-city-btn search-city-key",
                            attrs: { title: _vm.selectedCity.text },
                          },
                          [
                            _c(
                              "el-popover",
                              {
                                attrs: {
                                  placement: "bottom-start",
                                  width: "629",
                                  "visible-arrow":
                                    _vm.provinceCityConfig.arrowIsShow,
                                  trigger: "click",
                                },
                                model: {
                                  value:
                                    _vm.provinceCityConfig.provinceCityIsShow,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.provinceCityConfig,
                                      "provinceCityIsShow",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "provinceCityConfig.provinceCityIsShow",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "search-city-content",
                                    attrs: { slot: "reference" },
                                    slot: "reference",
                                  },
                                  [
                                    _c("i", [
                                      _vm._v(_vm._s(_vm.selectedCity.text)),
                                    ]),
                                    _c("span", {
                                      staticClass: "el-icon-arrow-down",
                                    }),
                                  ]
                                ),
                                _c("province-city", {
                                  ref: "provinceCity",
                                  attrs: {
                                    "grid-length": 7,
                                    enableHeader:
                                      _vm.provinceCityConfig.enableHeader,
                                    enableFooter:
                                      _vm.provinceCityConfig.enableFooter,
                                  },
                                  on: {
                                    confirm: _vm.handleCitySelectConfirm,
                                    cancel: _vm.handleCitySelectCancel,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("el-input", {
                          staticClass: "search-input search-input-key",
                          attrs: {
                            maxlength: 128,
                            placeholder: "多个关键词使用空格隔开",
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.search.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.personalCandidateSearch.keywords,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.personalCandidateSearch,
                                "keywords",
                                $$v
                              )
                            },
                            expression: "personalCandidateSearch.keywords",
                          },
                        }),
                        _c(
                          "span",
                          {
                            staticClass: "do-search-btn",
                            on: { click: _vm.search },
                          },
                          [_vm._v("搜索")]
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "more-select-btn" }, [
                      _c(
                        "span",
                        {
                          staticClass: "more-btn text-operate-btn",
                          on: { click: _vm.showMoreSearch },
                        },
                        [
                          _vm.isShowMoreSearch
                            ? [
                                _vm._v(
                                  "\n                                收起\n                                "
                                ),
                                _c("i", { staticClass: "el-icon-arrow-up" }),
                              ]
                            : [
                                _vm._v(
                                  "\n                                更多筛选条件\n                                "
                                ),
                                _c("i", { staticClass: "el-icon-arrow-down" }),
                              ],
                        ],
                        2
                      ),
                    ]),
                  ]),
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "title" } },
                [
                  _c("span", { staticClass: "form-item-label" }, [
                    _vm._v("职位"),
                  ]),
                  _c("el-input", {
                    staticClass: "search-input",
                    attrs: { maxlength: 128, placeholder: "请输入" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.search.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.personalCandidateSearch.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.personalCandidateSearch, "title", $$v)
                      },
                      expression: "personalCandidateSearch.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "companyName" } },
                [
                  _c("span", { staticClass: "form-item-label" }, [
                    _vm._v("公司"),
                  ]),
                  _c("el-input", {
                    staticClass: "search-input",
                    attrs: { maxlength: 128, placeholder: "请输入" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.search.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.personalCandidateSearch.companyName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.personalCandidateSearch,
                          "companyName",
                          $$v
                        )
                      },
                      expression: "personalCandidateSearch.companyName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "realName" } },
                [
                  _c("span", { staticClass: "form-item-label" }, [
                    _vm._v("姓名"),
                  ]),
                  _c("el-input", {
                    staticClass: "search-input",
                    attrs: { maxlength: 128, placeholder: "请输入" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.search.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.personalCandidateSearch.realName,
                      callback: function ($$v) {
                        _vm.$set(_vm.personalCandidateSearch, "realName", $$v)
                      },
                      expression: "personalCandidateSearch.realName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "mobile" } },
                [
                  _c("span", { staticClass: "form-item-label" }, [
                    _vm._v("手机"),
                  ]),
                  _c("el-input", {
                    staticClass: "search-input",
                    attrs: { maxlength: 128, placeholder: "请输入" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.search.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.personalCandidateSearch.mobile,
                      callback: function ($$v) {
                        _vm.$set(_vm.personalCandidateSearch, "mobile", $$v)
                      },
                      expression: "personalCandidateSearch.mobile",
                    },
                  }),
                ],
                1
              ),
              _vm.isShowMoreSearch
                ? [
                    _c(
                      "el-form-item",
                      { attrs: { prop: "email" } },
                      [
                        _c("span", { staticClass: "form-item-label" }, [
                          _vm._v("邮箱"),
                        ]),
                        _c("el-input", {
                          staticClass: "search-input",
                          attrs: { maxlength: 128, placeholder: "请输入" },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.search.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.personalCandidateSearch.email,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.personalCandidateSearch,
                                "email",
                                $$v
                              )
                            },
                            expression: "personalCandidateSearch.email",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "form-select-item",
                        attrs: { prop: "degree" },
                      },
                      [
                        _c("span", { staticClass: "form-item-label" }, [
                          _vm._v("学历"),
                        ]),
                        _c(
                          "el-select",
                          {
                            staticClass: "personal-el-select",
                            attrs: {
                              placeholder: "请选择学历",
                              "popper-class": "personal-el-dropmenu",
                            },
                            on: { change: _vm.handleDegree },
                            model: {
                              value: _vm.personalCandidateSearch.degree,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.personalCandidateSearch,
                                  "degree",
                                  $$v
                                )
                              },
                              expression: "personalCandidateSearch.degree",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { value: "1", label: "高中及以下" },
                            }),
                            _c("el-option", {
                              attrs: { value: "2", label: "大专" },
                            }),
                            _c("el-option", {
                              attrs: { value: "3", label: "本科" },
                            }),
                            _c("el-option", {
                              attrs: { value: "4", label: "硕士" },
                            }),
                            _c("el-option", {
                              attrs: { value: "5", label: "MBA" },
                            }),
                            _c("el-option", {
                              attrs: { value: "6", label: "博士" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "form-select-item",
                        attrs: { prop: "yearofexperience" },
                      },
                      [
                        _c("span", { staticClass: "form-item-label" }, [
                          _vm._v("工作年限"),
                        ]),
                        _c(
                          "el-select",
                          {
                            staticClass: "personal-el-select",
                            attrs: {
                              placeholder: "请选择工作年限",
                              "popper-class": "personal-el-dropmenu",
                            },
                            on: { change: _vm.handleYear },
                            model: {
                              value:
                                _vm.personalCandidateSearch.yearofexperience,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.personalCandidateSearch,
                                  "yearofexperience",
                                  $$v
                                )
                              },
                              expression:
                                "personalCandidateSearch.yearofexperience",
                            },
                          },
                          _vm._l(
                            _vm.yearOfExperiencesRangeGroupedItems,
                            function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.text, value: item.value },
                              })
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                    _c("el-form-item", { staticClass: "form-select-item" }),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _c("search-summary", {
        ref: "searchSummary",
        on: {
          "search-summary-remove": _vm.handleRemoteSummary,
          "clear-search": _vm.clearSearch,
        },
      }),
      _c(
        "div",
        { staticClass: "more-params personal-more-params" },
        [
          _c(
            "div",
            { staticClass: "personal-checkbox" },
            [
              _c(
                "el-checkbox",
                {
                  attrs: { size: "medium", value: _vm.isAllSelected },
                  on: { change: _vm.handleCheckALL },
                },
                [_vm._v("全选")]
              ),
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "import-candidate-btn",
              on: { click: _vm._onBatchImportClick },
            },
            [_vm._v("批量导入人才库")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "search-tip" }, [
      _c("img", {
        attrs: { src: require("@src/assets/images/personal_candidate.png") },
      }),
      _c("span", [
        _c("i", [_vm._v("私有人才库")]),
        _vm._v(
          "私有人才库  您的终身私有人才库，仅您自己可见，随身携带，离职后既不公开也不交接，还可在下一个猎必得猎企中继续使用"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }